import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: "/",
            name: "home",
            component: HomeView,
        },
    ],
});

router.beforeEach(async (to, from) => {

if (to.name !== "home") {
    // redirect the user to the home page
    return { name: "home" };
}

});

export default router
