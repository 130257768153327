import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { plugin, defaultConfig } from "@formkit/vue";
import PrimeVue from "primevue/config";
import App from './App.vue'
import router from './router'
// import * as Sentry from "@sentry/vue";

//Styles
import './assets/css/index.css';
import "primevue/resources/themes/lara-light-blue/theme.css";
import './assets/css/primevue-overrides.css';
import "primeicons/primeicons.css";

//UI Components
import { Icon } from "@iconify/vue";
import Button from "primevue/button";
import Tag from "primevue/tag";
import Tooltip from "primevue/tooltip";
import Message from "primevue/message";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Divider from 'primevue/divider';
import SplitButton from 'primevue/splitbutton';

const app = createApp(App);
app.use(createPinia());


app.use(router);
app.use(plugin, defaultConfig)
app.use(PrimeVue);


app.component("Icon", Icon)
app.component("Button", Button)
app.component("Tag", Tag)
app.component("Message", Message)
app.component("DataTable", DataTable)
app.component("Column", Column)
app.component("Divider", Divider)
app.component("SplitButton", SplitButton)
app.directive("Tooltip", Tooltip);


app.mount("#app");
