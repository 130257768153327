<script setup>
  import { ref } from 'vue';
  // import { auth } from '../../../firebase';
  // import { useFetch } from '@/use/useFetch';
  
  //from Local Storage
  const allMenus = ref(JSON.parse(localStorage.getItem('oneFoodMenu')) || []);
  
  //from Database
  // const { data, error, isLoading } = useFetch(`/menus/user/${auth.currentUser.uid}`);

  // watch(data, () => { allMenus.value = [...allMenus?.value, ...data?.value]})

  // watch(error, () => {
  //   console.log(error.value)
  // })

    const downloadMenu = (menuId) => {
      
     const menu = getMenuFromLocalStorage(menuId)

      const a = document.createElement('a');
      a.href = URL.createObjectURL(new Blob([JSON.stringify(menu)], { type: `text/json` }));
      a.download = '1FoodMenu_' + menu.menu_uid + '.json';
      a.click();

  }

  const getMenuFromLocalStorage = (id) => {
    let menu = null;
    const oneFoodMenu = JSON.parse(localStorage.getItem("oneFoodMenu")) || [];
    menu = oneFoodMenu.find((f) => f.menu_uid === id);
    return menu;
};
</script>
<template>
  <div class="mt-16 w-full mx-auto max-w-4xl" v-if="allMenus.length">
    <div class="flex flex-col lg:flex-row gap-4 mb-4 items-center justify-between">
      <h1>Your Menus</h1>
    </div>

    <div class="w-full grid grid-cols-1 lg:grid-cols-3 gap-4">
      <div class="card h-60 relative hover:bg-gray-200 duration-300"
        v-for="item in allMenus" :key="item?.menu_uid">
        <h2 class="title flex-shrink-0 truncate text-ellipsis  justify-center text-center mt-4 capitalize">{{ item.configs?.title }}</h2>
        <div class="grid grid-cols-2 gap-2 text-center m-auto w-full">
          <div class="my-4">
            <div class="text-gray-500">Categories</div>
            <div class="mt-4 font-bold text-lg">{{ item.categories.length }}</div>
          </div>
          <div class="my-4">
            <div class="text-gray-500">Products</div>
            <div class="mt-4 font-bold text-lg">{{ item.products.length }}</div>
          </div>
        </div>
        <Button class="text-center justify-center" severity="secondary" @click.prevent="() => downloadMenu(item?.menu_uid)">Download</Button>
        <!-- <div class="mt-auto" title="Your Plan">
          <Tag severity="success" v-if="item.isFromLocal">Free</Tag>
          <Tag severity="warning" v-else>Pro</Tag>
        </div> -->
      </div>
      <!-- <div v-if="isLoading" class="grid place-items-center cursor-pointer bg-gray-200 duration-300 h-60 rounded relative">
        <Loading class="!border-t-gray-400"/>
      </div> -->
    </div>
  </div>
  <div v-else>
    <div>No menus found</div>
  </div>
</template>